import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Banner from '../components/banner'
import ContactSection from '../components/contact/section'
import Courses from '../components/courses/'
import { selectedFilterEvent } from '../components/google-tag-manager/events'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Webinars from '../components/webinars'
import Newsletter from '../components/newsletter'

const CoursesTemplate = ({ data, location }) => {
  const banner = data.page.template.componentBanner
  const template = data.page.template.templateCourses
  const {
    allWpCourse: { nodes: courses },
    allWpLocation: { nodes: locations },
    allWpUserGroup: { nodes: userGroups },
    allWpWebinar: { nodes: webinars },
    allWpCategory: { nodes: categories },
  } = data

  const futureCourses = courses.filter((course) => {
    const courseDate = new Date(course.templateCourse.date)
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    return courseDate >= yesterday
  })

  const [selectedUserGroup, setSelectedUserGroup] = useState('')
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isDigital, setDigital] = useState(false)

  const doesCourseMatchSelectedLocations = (location) => {
    return location.nodes.some((item) => selectedLocation.includes(item.slug))
  }
  const doesCourseMatchSelectedUserGroups = (userGroup) => {
    return userGroup.nodes.some((item) => selectedUserGroup.includes(item.slug))
  }
  const doesWebinarMatchSelectedCategories = (category) => {
    return category.nodes.some((item) => selectedCategory.includes(item.slug))
  }

  useEffect(() => {
    selectedUserGroup
      ? selectedFilterEvent(selectedUserGroup)
      : selectedFilterEvent('alle')
    selectedLocation
      ? selectedFilterEvent(selectedLocation)
      : selectedFilterEvent('alle')
    selectedCategory
      ? selectedFilterEvent(selectedCategory)
      : selectedFilterEvent('alle')
  }, [selectedUserGroup, selectedLocation, selectedCategory])

  const applyFilter = (list) => {
    if (selectedLocation === '' && selectedUserGroup === '') {
      return futureCourses
    }
    return list.reduce((reducer, item) => {
      if (
        doesCourseMatchSelectedUserGroups(item.userGroups) &&
        doesCourseMatchSelectedLocations(item.locations)
      ) {
        reducer.push(item)
      } else if (
        doesCourseMatchSelectedUserGroups(item.userGroups) &&
        selectedLocation === ''
      ) {
        reducer.push(item)
      } else if (
        doesCourseMatchSelectedLocations(item.locations) &&
        selectedUserGroup === ''
      ) {
        reducer.push(item)
      } else if (
        isDigital &&
        item.templateCourse.digital &&
        doesCourseMatchSelectedUserGroups(item.userGroups)
      ) {
        reducer.push(item)
      } else if (
        isDigital &&
        item.templateCourse.digital &&
        selectedUserGroup === ''
      ) {
        reducer.push(item)
      }
      return reducer
    }, [])
  }

  const filterWebinars = (list) => {
    if (selectedCategory === '') {
      return webinars
    }
    return list.reduce((reducer, item) => {
      if (doesWebinarMatchSelectedCategories(item.categories)) {
        reducer.push(item)
      }
      return reducer
    }, [])
  }
  return (
    <Layout location={location}>
      <SEO
        title={data.page.title}
        metaTitle={data.page.seo.title}
        description={data.page.seo.metaDesc}
      />
      <Banner
        image={banner.bannerImage}
        excerpt={banner.excerpt}
        title={data.page.title}
        breadcrumbs={data.page.seo.breadcrumbs}
      />
      <Courses
        courses={applyFilter(futureCourses).map((item) => ({
          id: item.id,
          title: item.title,
          date: item.templateCourse.date,
          isDigital: item.templateCourse.digital,
          location: item.templateCourse.place,
          group: item.userGroups.nodes.name,
          link: item.templateCourse.link,
        }))}
        locations={locations}
        userGroups={userGroups}
        selectedUserGroup={selectedUserGroup}
        setSelectedUserGroup={setSelectedUserGroup}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        isDigital={isDigital}
        setDigital={setDigital}
        courseNotFoundText={template.noItemsFoundText}
      />

      <Webinars
        webinars={filterWebinars(webinars).map((item) => ({
          id: item.id,
          title: item.title,
          categories: item.categories.nodes,
          date: item.date,
          link: item.uri,
          price: item.templateWebinar.price,
          paid: item.templateWebinar.paid,
        }))}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        categories={categories}
      />

      {(template?.newsletterFormId) && (
         <Newsletter newsletterFormId={template.newsletterFormId} title={template.newsletterTitle} description={template.newsletterDescription} />
      )}

      <ContactSection
        title={template.title}
        image={template.contactImage}
        email={template.email}
        name={template.name}
        jobTitle={template.jobTitle}
        phone={template.phone}
      />
    </Layout>
  )
}

export default CoursesTemplate

export const query = graphql`
  query ($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      uri
      title
      template {
        ... on WpCoursesTemplate {
          componentBanner {
            excerpt
            bannerImage {
              title
              publicUrl
              sourceUrl
            }
          }
          templateCourses {
            noItemsFoundText
            email {
              target
              title
              url
            }
            jobTitle
            name
            title
            phone {
              target
              title
              url
            }
            contactImage {
              publicUrl
              title
              altText
            }
            newsletterTitle
            newsletterDescription
            newsletterFormId
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
    }
    allWpCourse(sort: { fields: templateCourse___date, order: ASC }) {
      nodes {
        templateCourse {
          date
          digital
          link {
            title
            url
            target
          }
          place
        }
        id
        title
        locations {
          nodes {
            name
            slug
            taxonomyName
          }
        }
        userGroups {
          nodes {
            taxonomyName
            name
            slug
          }
        }
      }
    }
    allWpWebinar(sort: { fields: date, order: DESC }) {
      nodes {
        templateWebinar {
          introductionText
          leadingText
          speakersList {
            image {
              publicUrl
              altText
            }
            position
            name
          }
          videoLink
          wysiwygContent
          price
          paid
        }
        date
        title
        id
        uri
        categories {
          nodes {
            name
            slug
            taxonomyName
          }
        }
      }
    }
    allWpUserGroup {
      nodes {
        name
        slug
      }
    }
    allWpCategory {
      nodes {
        name
        slug
      }
    }
    allWpLocation {
      nodes {
        name
        slug
      }
    }
  }
`
